const protobufjs=   require('protobufjs');
const protocol	=	require('./google_auth.proto.json');

export default function(data_string){

	if( typeof data_string != 'string' )	return null;

	const root			=	protobufjs.Root.fromJSON(protocol);
	const the_message	=	root.lookupType("MigrationPayload");

	const buffer		=	new Buffer.from(data_string,'base64');
	//	const buffer	=	new Buffer.from("CjsKCqmhlK7nInrM2CgSHndlYmFkbWluK251ZmFjZUB3ZWFyZWVudm95LmNvbRoHS2xhdml5byABKAEwAhAB",'base64');
	//	const buffer	=	new Buffer.from("CjsKCs2pm9R44zRtoXwSHndlYmFkbWluK251ZmFjZUB3ZWFyZWVudm95LmNvbRoHS2xhdml5byABKAEwAhAB",'base64');
	//	const buffer	=	new Buffer.from("CjUKEPqlBekzoNEukL7qlsjBCDYSDnBpQHJhc3BiZXJyeXBpGgtyYXNwYmVycnlwaSABKAEwAhABGAEgACjr4JKK+",'base64');

	const decoded     =   the_message.decode(buffer);
	return decoded;

	//	Implementing .toString using JSON.stringify on the parameters does some magic for converting Integers to their Enumerated values defined in the protocol buffer.
	//	However, it ruins the encoding of the 'secret'. We can't have this because we need it to remain as a Uint8Array(10) so we can base32 encode it properly.
	const human_readable	=	JSON.parse( JSON.stringify(decoded) );
	return human_readable;

};
